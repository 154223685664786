// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/cropperjs/dist/cropper.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploader-container.hover,.uploader-container:hover{color:rgba(111,66,193,.2);cursor:pointer}", "",{"version":3,"sources":["webpack://./resources/js/components/imageUploader.vue"],"names":[],"mappings":"AAWQ,oDACI,yBAAA,CACA,cADZ","sourcesContent":["\n    @import \"~cropperjs/dist/cropper.css\";\n\n    $color: #6f42c1;\n    .uploader-container {\n        /*display: flex;*/\n        /*justify-content: center;*/\n        /*vertical-align: middle;*/\n        /*align-items: center;*/\n        /*text-align: right;*/\n        /*min-height: 8rem;*/\n        &:hover, &.hover {\n            color: rgba($color, 20%);\n            cursor: pointer;\n        }\n\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
